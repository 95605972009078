import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimePeriodToggleComponent } from '../time-period-toggle/time-period-toggle.component';

@Component({
  selector: 'app-time-selection',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './time-selection.component.html',
  styleUrl: './time-selection.component.scss'
})
export class TimeSelectionComponent extends TimePeriodToggleComponent {

  @Input() mapLegendIcon: string = ''
  @Input() showCenturyLegend: boolean = true

}

