import { Pipe, PipeTransform } from '@angular/core';
import { RISK_COLORS } from '../utils/app.constant';

@Pipe({
  name: 'comparisonTable',
  standalone: true
})
export class ComparisonTablePipe implements PipeTransform {

  transform(object, indicatorName, timePeriod, type, returnType) {
    const data = object.data[type].Indicators.find((x) => x.headline.Title === indicatorName)
    switch (returnType) {
      case 'value':
        return data ? data.headline.Score[timePeriod.key] : 'N/A'
      case 'background-color':
        return data ? RISK_COLORS[data.headline.Cat[timePeriod.key]].background : 'transparent'
    }

  }

}
