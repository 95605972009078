import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as L from "leaflet";
import { DEFAULT_LOCATION_ID, MAP_BOX_ACCESS_TOKEN } from '../../../utils/app.constant';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss'
})
export class MapComponent implements OnInit {

  @ViewChild('leafletMap', { static: true }) mapElement: ElementRef;

  @Output() LocationChange = new EventEmitter<number>()

  map: any;
  initialZoom: number = 3
  mapView: Array<number> = [32, -52]
  markersLayer: any;

  locations = []
  selectedLocationId = DEFAULT_LOCATION_ID

  constructor(private appService: AppService) {
    this.appService.getData().subscribe((response) => {
      this.locations = response.map((x) => { return { id: x.id, ...x.locationData } })
      this.loadMap()
    })
  }

  ngOnInit(): void { }

  loadMap() {
    /** loading map  mapbox://styles/v1/mehroz/clilc0kcv00h601r13duj1k06*/
    // always initlize map this way , as it doesnt throw an error of map container already initialized
    // World map bounds
    var southWest = L.latLng(-89.98155760646617, -180), northEast = L.latLng(89.99346179538875, 180), bounds = L.latLngBounds(southWest, northEast);
    this.map = L.map(this.mapElement.nativeElement, {
      center: this.mapView,
      zoom: this.initialZoom,
      minZoom: 3,
    });;
    // Add OSM base map , getting map data from mapbox api 
    L.tileLayer('https://api.mapbox.com/styles/v1/mehroz/cltmyvifh00o901nrbm6k14nh/tiles/{z}/{x}/{y}?access_token=' + MAP_BOX_ACCESS_TOKEN, {
      id: 'mapbox/light-v9',
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 10,
      tileSize: 512,
      zoomOffset: -1,
    }).addTo(this.map);

    // Bounding map to world: fix of marker issues
    this.map.setMaxBounds(bounds);
    this.map.on('drag', () => {
      this.map?.panInsideBounds(bounds, { animate: false });
    });

    this.addMarkers()

    // var imageUrl = 'https://maps.lib.utexas.edu/maps/historical/newark_nj_1922.jpg',
    //   imageBounds = [];
    // L.imageOverlay(imageUrl, imageBounds, { opacity: 0.1, className: 'test' }).addTo(this.map);

    // this.addBlackishOverLayOnTopOfMap()

    // // Event listener for map load
    // this.map.on('load', function () {
    //   // Show the map once it's loaded
    //   console.log("map loaded successfully")
    //   // document.getElementById('map').style.display = 'block';
    // });
  }

  addBlackishOverLayOnTopOfMap() {
    // Define your custom grid layer
    var CustomGridLayer = L.GridLayer.extend({
      createTile: function () {
        // Create a <div> element for each tile
        var tile = document.createElement('div');
        // Add custom styling to the tile
        tile.style.width = this.getTileSize().x + 'px';
        tile.style.height = this.getTileSize().y + 'px';
        tile.style.backgroundColor = 'rgba(0, 0, 0, 0.7)'; // black color with 20% opacity

        // Optionally, you can add additional content or customization to the tile here

        // Return the tile element
        return tile;
      }
    });

    // Create an instance of your custom grid layer
    var customGrid = new CustomGridLayer();

    // Add the custom grid layer to the map
    customGrid.addTo(this.map);
  }

  addMarkers() {
    // removing old layer in case exist
    this.markersLayer?.remove()

    // adding layer for marker
    this.markersLayer = L.layerGroup().addTo(this.map);

    const markerClick = (e) => {
      this.selectedLocationId = this.locations.find((x) => x.id == e.sourceTarget.options.icon.options.id).id
      this.addMarkers()
      this.LocationChange.emit(e.sourceTarget.options.icon.options.id)
    }

    const tooltipTextColor = (item) => {
      if (window.location.pathname.includes("comparison")) {
        return '#fff'
      } else {
        return this.selectedLocationId == item.id ? '#fff' : '#B5B5B5'
      }
    }

    this.locations.forEach((item: any) => {
      new L.marker([item.latitude, item.longitude], { icon: this.getIcon(item) })
        // offset: item.property_id == this.slug ? [8, -12] : [4, 4], autoPan: true 
        .bindTooltip(`<div style="color:${tooltipTextColor(item)}"> ${item.address} </div>`, { permanent: true, direction: 'top' })
        .addTo(this.markersLayer).on({ click: markerClick });

    })
    // this.map.flyTo([this.selectedProperty.latitude, this.selectedProperty.longitude])
  }

  getIcon(item) {

    const marker = `
    <svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b_16636_30186)">
    <circle cx="19.25" cy="18.5" r="18.5" fill="url(#paint0_radial_16636_30186)" fill-opacity="0.3"/>
    </g>
    <g clip-path="url(#clip0_16636_30186)">
    <path d="M7.68055 17.0561C7.39239 13.633 9.90903 10.0714 13.3094 9.02881C10.6198 11.2617 8.83321 13.8175 7.68055 17.0561Z" fill="white"/>
    <path d="M13.2803 27.8973C10.0241 26.9193 7.56506 23.5792 7.73796 20.3037C8.96746 23.3485 10.7445 25.7198 13.2803 27.8973Z" fill="white"/>
    <path d="M30.0222 20.0088C29.9454 26.6428 21.944 31.5515 15.6332 28.8573C17.2373 29.1064 18.8414 29.0972 20.3783 28.8296C25.085 28.0084 28.7927 24.5022 30.0222 20.0088Z" fill="white"/>
    <path d="M15.6436 8.07847C22.1657 5.25508 30.4072 10.6066 30.0998 17.4713C29.754 15.8935 29.1489 14.4265 28.2652 13.1347C25.6237 9.13955 20.4751 7.1927 15.6532 8.07847H15.6436Z" fill="white"/>
    <path d="M30.023 20.0086C40.2913 17.8772 23.1263 18.1171 18.7558 18.1171C16.5849 18.1171 14.8175 16.4378 14.7695 14.3526C14.7118 11.8245 14.6158 7.09112 14.4525 4.56299C14.2892 7.12803 14.1835 11.9075 14.1355 14.4818C14.0971 16.5301 12.3873 18.1817 10.2645 18.2371C8.52591 18.2832 3.44461 18.3201 7.61339 17.056C1.69641 18.5046 8.52591 18.5969 10.2645 18.643C12.3873 18.7076 14.0971 20.3592 14.1355 22.3983C14.1931 24.9726 14.2892 29.8628 14.4525 32.437C14.6062 29.9089 14.7118 25.0556 14.7695 22.5275C14.8175 20.4422 16.5849 18.763 18.7558 18.763C23.1167 18.763 39.3403 17.8588 30.023 19.9901V20.0086Z" fill="white"/>
    </g>
    <defs>
    <filter id="filter0_b_16636_30186" x="-14.25" y="-15" width="67" height="67" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_16636_30186"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_16636_30186" result="shape"/>
    </filter>
    <radialGradient id="paint0_radial_16636_30186" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.25 18.5) rotate(90) scale(18.5)">
    <stop stop-color="#DDDDDD" stop-opacity="0"/>
    <stop offset="1" stop-color="#DDDDDD"/>
    </radialGradient>
    <clipPath id="clip0_16636_30186">
    <rect width="28" height="27.8833" fill="white" transform="translate(5.25 4.57227)"/>
    </clipPath>
    </defs>
    </svg>    
    `

    const selectedMarker = `
    <svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b_16636_30182)">
    <circle cx="18.75" cy="18.5" r="18.5" fill="url(#paint0_radial_16636_30182)"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5097 18.1139C25.1645 18.094 38.9811 18.0454 29.5231 20.0086V19.9901C36.981 18.2841 28.075 18.523 21.9869 18.6863C20.4689 18.727 19.1262 18.763 18.2559 18.763C16.085 18.763 14.3176 20.4422 14.2696 22.5275C14.267 22.6419 14.2643 22.761 14.2615 22.8845C14.2023 25.49 14.0994 30.0233 13.9526 32.437C13.8048 30.107 13.7121 25.8795 13.6531 23.1895C13.6469 22.9076 13.6411 22.6425 13.6356 22.3983C13.5972 20.3592 11.8874 18.7076 9.76463 18.643C9.68642 18.641 9.5979 18.6388 9.50071 18.6364C7.43738 18.5858 1.4627 18.4395 7.11351 17.056C2.94473 18.3201 8.02603 18.2832 9.76463 18.2371C11.8874 18.1817 13.5972 16.5301 13.6356 14.4818C13.6837 11.9075 13.7893 7.12803 13.9526 4.56299C14.1018 6.87298 14.1949 11.024 14.2538 13.6517C14.2594 13.8999 14.2646 14.1344 14.2696 14.3526C14.3176 16.4378 16.085 18.1171 18.2559 18.1171C18.6008 18.1171 19.0253 18.1156 19.5097 18.1139ZM15.1437 8.07847C21.6658 5.25508 29.9073 10.6066 29.5999 17.4713C29.2541 15.8935 28.649 14.4265 27.7653 13.1347C25.1238 9.13955 19.9752 7.1927 15.1533 8.07847H15.1437ZM7.18068 17.0562C6.89251 13.633 9.40915 10.0715 12.8095 9.02887C10.12 11.2617 8.33333 13.8176 7.18068 17.0562ZM12.7804 27.8976C9.52418 26.9195 7.06518 23.5795 7.23808 20.304C8.46758 23.3488 10.2446 25.7201 12.7804 27.8976ZM15.1333 28.8572C21.4441 31.5514 29.4455 26.6428 29.5223 20.0087C28.2928 24.5022 24.5851 28.0083 19.8784 28.8295C18.3415 29.0971 16.7374 29.1063 15.1333 28.8572Z" fill="white"/>
    <defs>
    <filter id="filter0_b_16636_30182" x="-14.75" y="-15" width="67" height="67" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_16636_30182"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_16636_30182" result="shape"/>
    </filter>
    <radialGradient id="paint0_radial_16636_30182" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.75 18.5) rotate(90) scale(18.5)">
    <stop offset="0.599" stop-color="white" stop-opacity="0"/>
    <stop offset="1" stop-color="white"/>
    </radialGradient>
    </defs>
    </svg>
    `

    const svg = (item) => {
      if (window.location.pathname.includes("comparison")) {
        return selectedMarker
      } else {
        return this.selectedLocationId == item.id ? selectedMarker : marker
      }
    }

    const icon = L.divIcon({
      className: "marker",
      html: svg(item),
      // iconSize: [40, 40],
      // iconAnchor: this.slug == item.property_id && [12, 24],   // moving marker tip to exactly at circle clicked
      // popupAnchor: [7, -16],
      ...item
    });

    return icon
  }

}
