import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { RiskFeaturesComponent } from '../risk-features/risk-features.component';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';
import { TimePeriod, Indicator } from '../../../types/app.interface';
import { ResilienceFeaturesComponent } from '../resilience-features/resilience-features.component';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'app-data-tab',
  standalone: true,
  imports: [
    CommonModule,
    RiskFeaturesComponent,
    ResilienceFeaturesComponent
  ],
  templateUrl: './data-tab.component.html',
  styleUrl: './data-tab.component.scss'
})
export class DataTabComponent implements OnChanges {

  @Output() CloseDataTab = new EventEmitter<void>()
  @Output() TimePeriodSelection = new EventEmitter<TimePeriod>()
  @Output() ChangeImpactType = new EventEmitter<string>()

  @Input() selectedIndicator: Indicator
  @Input() selectedTimePeriod: TimePeriod
  @Input() impactType

  greyishBoxHeight: string;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, public appService: AppService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedIndicator']) {
      this.selectedIndicator && this.setGreyishBoxHeight()
    }
  }

  setGreyishBoxHeight() {
    setTimeout(() => {
      if (isPlatformBrowser(this.platformId)) {
        const featureContainerElement = document.getElementById('features-container')
        this.greyishBoxHeight = `${featureContainerElement?.offsetHeight + 14}px`
      }
    })
  }

  closeDataTab = () => this.CloseDataTab.next()

}
