export const RISK_KEY = 'risk'

export const RESILIENCE_KEY = 'resilience'

export const INDICTORS_DICT = {
    "Overall": {
        icon: 'assets/images/svgs/overall.svg',
        explainer: `Overall Physical Risk Score is the ranking of the overall risk profile of a location based on all risk exposures.`,
        mapLegend: "assets/images/svgs/overview-map-legends/overall.svg",
        mapImage: {
            [RISK_KEY]: {
                "Current": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Overall2025.png",
                "EarlyCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Overall2035.png",
                "MidCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Overall2050.png",
                "EndCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Overall2100.png"
            },
            resilience: {
                "Current": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/OverallRAJ2025.png",
                "EarlyCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/OverallRAJ2035.png",
                "MidCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/OverallRAJ2050.png",
                "EndCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/OverallRAJ2100.png"
            }
        },
        dataSet: []
    },
    "Heat Stress": {
        icon: 'assets/images/svgs/heat-stress.svg',
        mapLegend: "assets/images/svgs/overview-map-legends/heat-stress.svg",
        explainer: "Extreme heat and heatwaves significantly increase the energy consumption required for cooling while also presenting grave risks to public health.",
        mapImage: {
            [RISK_KEY]: {
                "Current": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Heat2025.png",
                "EarlyCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Heat2035.png",
                "MidCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Heat2050.png",
                "EndCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Heat2100.png"
            },
            resilience: {
                "Current": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/HeatRAJ2025.png",
                "EarlyCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/HeatRAJ2035.png",
                "MidCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/HeatRAJ2050.png",
                "EndCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/HeatRAJ2100.png"
            }
        },
        dataSet: {
            [RISK_KEY]: [
                'Annual maximum temperature',
                'Annual 5-day average maximum temperature',
                'Cooling degree days (CDD)',
                'Number of local hot days in a year'
            ],
            [RESILIENCE_KEY]: [
                "Urban built up density"
            ]
        }
    },
    "Drought": {
        icon: 'assets/images/svgs/drought.svg',
        mapLegend: "assets/images/svgs/overview-map-legends/drought.svg",
        explainer: "The increasing frequency and severity of dry spells and droughts imposes substantial stress on water systems in the form of reduced fresh water supply and chronic water shortages.",
        mapImage: {
            [RISK_KEY]: {
                "Current": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Drought2025.png",
                "EarlyCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Drought2035.png",
                "MidCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Drought2050.png",
                "EndCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Drought2100.png"
            },
            [RESILIENCE_KEY]: {
                "Current": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/DroughtRAJ2025.png",
                "EarlyCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/DroughtRAJ2035.png",
                "MidCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/DroughtRAJ2050.png",
                "EndCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/DroughtRAJ2100.png"
            }
        },
        dataSet: {
            [RISK_KEY]: [
                'Maximum number of consecutive dry days ',
                'Number of extremely hot days in a year',
                'Water supply',
                'Water demand',
                'Seasonal variability of water supply'
            ],
            [RESILIENCE_KEY]: [
                "Water treatment and storage facilities in proximity",
                "Wastewater treatment facilities in proximity",
                "Emergency water access"
            ]
        }
    },
    "Hurricane Wind": {
        icon: 'assets/images/svgs/hurricane-wind.svg',
        mapLegend: "assets/images/svgs/overview-map-legends/hurricane-wind.svg",
        explainer: "Warmer ocean temperatures lead to more intense hurricanes and tropical cyclones with stronger winds that cause severe damage to real estate and negatively impact valuations.",
        mapImage: {
            [RISK_KEY]: {
                "Current": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Wind2025.png",
                "EarlyCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Wind2025.png",
                "MidCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Wind2025.png",
                "EndCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Wind2025.png"
            },
            [RESILIENCE_KEY]: {
                "Current": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/WindRAJ2025.png",
                "EarlyCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/WindRAJ2025.png",
                "MidCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/WindRAJ2025.png",
                "EndCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/WindRAJ2025.png"
            }
        },
        dataSet: {
            [RISK_KEY]: [
                'Annual frequency of hurricanes',
                'Return period of hurricanes',
                'Average maximum sustained wind speed',
                'Highest potential hurricane category'
            ],
            [RESILIENCE_KEY]: [
                "Building strength rating",
                "Surface porosity"
            ]
        }
    },
    "Inland Flooding": {
        icon: 'assets/images/svgs/inland-flooding.svg',
        mapLegend: "assets/images/svgs/overview-map-legends/inland-flooding.svg",
        explainer: "Intense precipitation and the increased frequency of storms causes flash flooding that can have a devastating impact on infrastructure and populations, leading to higher insurance premiums, property devaluation and significant investments in adaptation measures.",
        mapImage: {
            [RISK_KEY]: {
                "Current": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Inland2025.png",
                "EarlyCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Inland2035.png",
                "MidCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Inland2050.png",
                "EndCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Inland2100.png"
            },
            [RESILIENCE_KEY]: {
                "Current": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/InlandRAJ2025.png",
                "EarlyCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/InlandRAJ2035.png",
                "MidCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/InlandRAJ2050.png",
                "EndCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/InlandRAJ2100.png"
            }
        },
        dataSet: {
            [RISK_KEY]: [
                'Maximum number of consecutive wet days',
                'Average daily mean precipitation',
                'Number of local high precipitation days in a year',
                'Number of days with total precipitation greater than 2 inches'
            ],
            [RESILIENCE_KEY]: [
                "Surface porosity",
                "Coverage of water storage and control facilities",
                "Presence of direct flood barrier",
                "Presence of nature based flood solutions"
            ]
        }
    },
    "Coastal Flooding": {
        icon: 'assets/images/svgs/coastal-flooding.svg',
        mapLegend: "assets/images/svgs/overview-map-legends/coastal-flooding.svg",
        explainer: "Global sea level rise increases the frequency and intensity of coastal inundations that causes structural damage to property and infrastructure, increases maintenance costs and renders locations uninsurable.",
        mapImage: {
            [RISK_KEY]: {
                "Current": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Coastal2025.png",
                "EarlyCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Coastal2035.png",
                "MidCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Coastal2050.png",
                "EndCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Coastal2100.png"
            },
            [RESILIENCE_KEY]: {
                "Current": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/CoastalRAJ2025.png",
                "EarlyCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/CoastalRAJ2035.png",
                "MidCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/CoastalRAJ2050.png",
                "EndCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/CoastalRAJ2100.png"
            }
        },
        dataSet: {
            [RISK_KEY]: [
                '100-year coastal flooding inundation depth',
                '250-year coastal flooding inundation depth',
                '1000-year coastal flooding inundation depth',
                'Mean sea level change'
            ],
            [RESILIENCE_KEY]: [
                "Surface porosity",
                "Coverage of water storage and control facilities",
                "Presence of direct flood barrier",
                "Presence of nature based flood solutions"
            ]
        }
    },
    "Wildfire": {
        icon: 'assets/images/svgs/wild-fire.svg',
        mapLegend: "assets/images/svgs/overview-map-legends/wildfire.svg",
        explainer: "Both natural and man-made wildfires are amplified by dry spells and heatwaves as well as topographical changes such as urban expansion, damaging property and utilities and causing erosion to economic activity while rendering locations uninsurable.",
        mapImage: {
            [RISK_KEY]: {
                "Current": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Fire2025.png",
                "EarlyCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Fire2035.png",
                "MidCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Fire2050.png",
                "EndCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/Fire2050.png"
            },
            [RESILIENCE_KEY]: {
                "Current": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/FireRAJ2025.png",
                "EarlyCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/FireRAJ2035.png",
                "MidCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/FireRAJ2050.png",
                "EndCentury": "https://climatealpha-public.s3.ap-southeast-1.amazonaws.com/resources/alpha-geo/miniapp+maps/FireRAJ2050.png"
            }
        },
        dataSet: {
            [RISK_KEY]: [
                'Maximum number of consecutive dry days',
                'Number of extremely hot days in a year',
                'Availability of burnable fuels in a 2 kilometer radius'
            ],
            [RESILIENCE_KEY]: [
                "Number of fire monitoring stations in proximity",
                "Number of fire response stations in proximity",
                "Number of fire prevention stations in proximity"
            ]
        }
    },
}

export const DEFAULT_INDICATOR_NAME = 'Overall'

export const TIME_PERIODS = [
    { label: 'Current', year: '2020-2025', key: 'Current', isFirst: true },
    { label: 'Early Century', year: '2030-2035', key: 'EarlyCentury' },
    { label: 'Mid Century', year: '2045-2050', key: 'MidCentury' },
    { label: 'End Century', year: '2095-2100', key: 'EndCentury', isLast: true },
]

export const RESILIENCE_FEATURE = [
    { label: 'This Location', key: 'Local', },
    { label: 'Regional Average', key: 'Regional' },
    { label: 'National Average', key: 'National' },
];

export const DEFAULT_TIME_PERIOD = TIME_PERIODS[1]

export const MAP_BOX_ACCESS_TOKEN = "pk.eyJ1IjoibWVocm96IiwiYSI6ImNrYnFqOTV4cjB4YzAycGxtcG1oaGM3Y2UifQ.gb3cX7FWRUY73ELF9lhEHg"

export const DEFAULT_LOCATION_ID = 1


export const GREEN_COLOR = '#099'
export const BG_GREEN_COLOR = 'rgba(0, 153, 153, 0.2)'

export const YELLOW_COLOR = '#EA8814'
export const BG_YELLOW_COLOR = 'rgba(234, 174, 20, 0.2)'

export const RED_COLOR = '#CC625B'
export const BG_RED_COLOR = 'rgba(129, 0, 0, 0.2)'

export const RISK_COLORS = {
    "Very Low Risk": {
        "color": GREEN_COLOR,
        "background": BG_GREEN_COLOR
    },
    "Very Low": {
        "color": GREEN_COLOR,
        "background": BG_GREEN_COLOR
    },
    "Low Risk": {
        "color": GREEN_COLOR,
        "background": BG_GREEN_COLOR
    },
    "Low": {
        "color": GREEN_COLOR,
        "background": BG_GREEN_COLOR
    },
    "Medium Risk": {
        "color": YELLOW_COLOR,
        "background": BG_YELLOW_COLOR
    },
    "Medium": {
        "color": YELLOW_COLOR,
        "background": BG_YELLOW_COLOR
    },
    "High Risk": {
        "color": RED_COLOR,
        "background": BG_RED_COLOR
    },
    "High": {
        "color": RED_COLOR,
        "background": BG_RED_COLOR
    },
    "Very High Risk": {
        "color": RED_COLOR,
        "background": BG_RED_COLOR
    },
    "Very High": {
        "color": RED_COLOR,
        "background": BG_RED_COLOR
    }
};

export const RESILIENCE_COLORS = {
    "Very Low Risk": {
        "color": RED_COLOR,
        "background": BG_RED_COLOR
    },
    "Very Low": {
        "color": RED_COLOR,
        "background": BG_RED_COLOR
    },
    "Low Risk": {
        "color": RED_COLOR,
        "background": BG_RED_COLOR
    },
    "Low": {
        "color": RED_COLOR,
        "background": BG_RED_COLOR
    },
    "Medium Risk": {
        "color": YELLOW_COLOR,
        "background": BG_YELLOW_COLOR
    },
    "Medium": {
        "color": YELLOW_COLOR,
        "background": BG_YELLOW_COLOR
    },
    "High Risk": {
        "color": GREEN_COLOR,
        "background": BG_GREEN_COLOR
    },
    "High": {
        "color": GREEN_COLOR,
        "background": BG_GREEN_COLOR
    },
    "Very High Risk": {
        "color": GREEN_COLOR,
        "background": BG_GREEN_COLOR
    },
    "Very High": {
        "color": GREEN_COLOR,
        "background": BG_GREEN_COLOR
    }
};


export const LOCATION_IMPACT_COLORS = {
    [RISK_KEY]: RISK_COLORS,
    [RESILIENCE_KEY]: RESILIENCE_COLORS
}
