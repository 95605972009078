import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TIME_PERIODS, DEFAULT_TIME_PERIOD } from '../../../utils/app.constant';
import { TimePeriod, Indicator } from '../../../types/app.interface';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'app-risk-features',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './risk-features.component.html',
  styleUrl: './risk-features.component.scss'
})
export class RiskFeaturesComponent {

  @Output() TimePeriodSelection = new EventEmitter<TimePeriod>()

  timePeriods: TimePeriod[] = TIME_PERIODS
  @Input() selectedTimePeriod: TimePeriod = DEFAULT_TIME_PERIOD

  @Input() selectedIndicator: Indicator;
  @Input() greyishBoxHeight: string = ''

  constructor(public appService: AppService) { }


}
