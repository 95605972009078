import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class AppService {

  constructor(private http: HttpClient) { }

  getData(): Observable<any> {
    return this.http.get<any>('assets/data.json');
  }

  getContainerHeight(classname): string | void {
    const availableHeight = window.innerHeight;
    const indicatorsElement = document.getElementsByClassName(classname)[0]
    if (indicatorsElement) {
      return `${availableHeight - indicatorsElement['offsetTop'] - 15}px`
    }
  }

  updateAppLocalStorage(key, value?) {
    let appState = this.getAppState()
    if (!appState) appState = Object.assign({})
    appState[key] = value
    localStorage.setItem('app-state', JSON.stringify(appState))
  }

  getAppState() {
    return JSON.parse(localStorage.getItem("app-state"))
  }

}