<div class="d-flex">
  <div class="left-panel border-right relative">
    <div class="br-bottom">
      <app-tabs></app-tabs>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="mt-2">
            <div class="d-flex align-items-center">
              <img src="assets/images/svgs/heat-stress.svg">
              <h1 class="uppercase fs-20 fw-600 lh-150-prcnt title mx-2">{{selectedIndicatorName}}</h1>
            </div>
            <p class="fs-12 fw-400 lh-150-prcnt my-4" innerHtml="  {{indicatorsDict[selectedIndicatorName].explainer}}">
            </p>
          </div>
          <span class="dataset-label uppercase fs-14 fw-600 lh-150-prcnt my-2">DATASETS </span>
          <div class="dataset-container mt-2">
            <div class="d-flex">
              <div class="fs-12 fw-400 lh-24 py-2 mono-300 w-50 text-center" (click)="onDatasetImpactChange('risk')"
                [ngClass]="{'selected' : datasetImpactType == 'risk' }">
                <span class="cursor-pointer">Risk Data</span>
              </div>
              <div class="fs-12 fw-400 lh-24 py-2 w-50 text-center" (click)="onDatasetImpactChange('resilience')"
                [ngClass]="{'selected' : datasetImpactType == 'resilience' }">
                <span class="cursor-pointer">Resilience Data</span>
              </div>
            </div>
            <div class="p-3 pb-3 scroll-x" [ngStyle]="{ 'height' : datasetScrollHeight }">
              <div class="fs-11 fw-400 lh-100-prcnt italic pb-1"
                [ngClass]="{'d-none' : selectedIndicatorName == 'Overall' }" *ngFor="let item of dataSets">{{item}}
              </div>
              <div *ngFor="let item of indicatorsDict | keyvalue : returnZero"
                [ngClass]="{'d-none' : selectedIndicatorName != 'Overall' || item.key == 'Overall' }">
                <div class="pb-3">
                  <div class="title fs-11 fw-300 lh-100-prcnt mono-300 pb-1">
                    {{item.key}} {{ datasetImpactType == 'resilience' ? 'Adaption' : ''  }}
                  </div>
                  <div class="fs-11 fw-400 lh-100-prcnt italic pb-1"
                    *ngFor="let m of item.value.dataSet[datasetImpactType]">
                    {{m}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- indicators -->
    <div class="container-fluid indicators-container">
      <div class="row br-top pt-3">
        <div class="col-6 " *ngFor="let item of indicatorsDict | keyvalue : returnZero">
          <div class="indicator-item-box mb-3 w-100 pl-2"
            [ngClass]="{'selected' : item.key  === selectedIndicatorName }" (click)="selectIndicator(item)">
            <img src="{{item.value.icon}}">
            <span class="fs-12 fw-400 lh-normal">{{item.key}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="right-panel px-0 relative">
    <div class="map-header" [ngStyle]="{'justify-content' : showWelcomeBanner ? 'space-between' : 'end'  }">
      <div class="welcome-text p-3 fs-11 fw-400 lh-100-prcnt mx-3 w-100" *ngIf="showWelcomeBanner">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="fs-14 fw-700 lh-normal">Welcome to AlphaGeo’s demo microsite!</h4>
          <img class="cursor-pointer" src="assets/images/svgs/cross.svg" (click)="showWelcomeBanner = false">
        </div>
        <div class="fs-12 fw-400 lh-normal">
          Here you can explore our proprietary datasets capturing the risk and resilience profile of several selected
          locations. To access data on locations of interest to you, please go to our <a
            href="https://app.alphageo.ai/trial_setup" target="_blank" class="fs-13 fw-700 underline">Login Page
          </a> and register to create
          your own free account.
          <div class="d-flex align-items-center mt-3">
            <input type="checkbox" (change)="onCheckBoxChange($event)">
            <span class="ml-2">Do not show this again</span>
          </div>
        </div>
      </div>
      <div class="score-toggle-container mr-3">
        <div class="d-flex selection-bar">
          <div
            class="fs-12 fw-400 lh-normal w-50 d-flex align-items-center justify-content-center cursor-pointer {{ mapImpactType == 'risk' ? 'selected' : 'not-selected' }}"
            (click)="mapImpactType = 'risk'; setMapImage()">
            Physical Climate Risk
          </div>
          <div
            class="fs-12 fw-400 lh-normal w-50 d-flex align-items-center justify-content-center mono-300 cursor-pointer {{ mapImpactType == 'resilience' ? 'selected' : 'not-selected' }}"
            (click)="mapImpactType = 'resilience'; setMapImage()">
            Resilience-Adjusted Risk
          </div>
        </div>
        <div class="description p-3 fs-11 fw-400 lh-100-prcnt mt-2">
          {{mapDecription[mapImpactType]}}
        </div>
      </div>
    </div>
    <canvas class="img_area" style="cursor: pointer;" #canvas
      style="width: 100%; height: 100%;object-fit: cover;"></canvas>
    <div>
      <app-time-selection (TimePeriodSelection)="selectedTimePeriod = $event; setMapImage()"
        [mapLegendIcon]="indicatorsDict[selectedIndicatorName].mapLegend">
      </app-time-selection>
    </div>
    <div class="d-flex justify-content-center w-100 app-loader-container" *ngIf="showLoader">
      <app-loader></app-loader>
    </div>
  </div>
</div>
