import { AfterViewChecked, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { INDICTORS_DICT, DEFAULT_TIME_PERIOD, DEFAULT_LOCATION_ID, GREEN_COLOR, RED_COLOR, RISK_COLORS } from '../../../utils/app.constant';
import { Indicator, TimePeriod } from '../../../types/app.interface';
import { AppService } from '../../../services/app.service';
import { MapComponent } from '../../../shared/components/map/map.component';
import { TabsComponent } from '../../../shared/components/tabs/tabs.component';
import { TimePeriodToggleComponent } from '../../../shared/components/time-period-toggle/time-period-toggle.component';
import { TimeSelectionComponent } from '../../../shared/components/time-selection/time-selection.component';
import { DataTabComponent } from '../data-tab/data-tab.component';
import { MarkerSliderComponent } from '../marker-slider/marker-slider.component';


@Component({
  selector: 'app-location-score',
  standalone: true,
  imports: [
    CommonModule,
    TabsComponent,
    MapComponent,
    DataTabComponent,
    MarkerSliderComponent,
    MapComponent,
    TimePeriodToggleComponent,
    TimeSelectionComponent,
  ],
  templateUrl: './location-score.component.html',
  styleUrl: './location-score.component.scss'
})
export class LocationScoreComponent implements AfterViewChecked {

  @ViewChild(MarkerSliderComponent) sliderComponent: MarkerSliderComponent

  indicatorsDict = INDICTORS_DICT;

  selectedLocationJson: any

  selectedIndicator: Indicator
  selectedTimePeriod: TimePeriod = DEFAULT_TIME_PERIOD

  response: any;

  colors = RISK_COLORS

  impactType: string | unknown;

  constructor(public appService: AppService, private cdr: ChangeDetectorRef) {
    this.appService.getData().subscribe((response) => {
      this.response = response
      this.onLocationChange()
    })
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges()
  }

  onLocationChange(id = DEFAULT_LOCATION_ID) {
    this.selectedLocationJson = this.response.find((x) => x.id === id)
    this.updateComponent()
  }

  onCenturyChange(selectedTimePeriod: TimePeriod) {
    this.selectedTimePeriod = selectedTimePeriod
    this.updateComponent()
  }

  updateComponent() {
    for (let key in this.selectedLocationJson.data) {
      this.selectedLocationJson.data[key].sliderOptions = this.sliderConfigurations(key)
    }
  }

  onIndictorSelection(item) {
    this.selectedIndicator = item
    document.getElementsByClassName('data-tab')[0].setAttribute('style', 'z-index:1000')
    document.getElementsByClassName('data-tab')[0].classList.remove("animate-backward")
    document.getElementsByClassName('data-tab')[0].classList.add("animate-farward")
  }

  closeDataTab() {
    this.selectedIndicator = undefined;
    document.getElementsByClassName('data-tab')[0].classList.add("animate-backward")
  }

  sliderConfigurations(key) {
    const value = this.selectedLocationJson.data[key].Headline.Score[this.selectedTimePeriod.key]
    const benchmark = this.selectedLocationJson.data[key].Benchmark

    const color = (): any => {
      const mean = benchmark['Score'][this.selectedTimePeriod.key].mean
      return value < mean ? GREEN_COLOR : RED_COLOR
    }

    return {
      value: value,
      ticks: [
        benchmark.Score[this.selectedTimePeriod.key].min,
        benchmark.Score[this.selectedTimePeriod.key].mean,
        benchmark.Score[this.selectedTimePeriod.key].max
      ],
      label: benchmark.Title,
      color: color(),
      id: `${key}-slider-${this.selectedLocationJson.id}`,
      tickUnit: "",
      ceilLabel: "Best",
      floorLabel: "Worst",
      tickIndexForLabel: 1,
    }
  }

  onPanelChange(event) {
    this.selectedIndicator = this.selectedLocationJson.data[event].Indicators.find(m => m.headline.Title == this.selectedIndicator.headline.Title)
    this.impactType = event
  }

  returnZero(): any { return 0 }



}
