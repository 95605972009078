import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { NgxSliderModule, Options, SliderComponent } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-marker-slider',
  standalone: true,
  imports: [NgxSliderModule],
  templateUrl: './marker-slider.component.html',
  styleUrl: './marker-slider.component.scss'
})
export class MarkerSliderComponent implements OnChanges {

  @ViewChild(SliderComponent) slider: SliderComponent
  @Input() sliderOptions
  @Input() value: number = 0;
  options: Options = {
    floor: 0,
    ceil: 0,
  };

  // reloading complete slider [ @Input() sliderOptions ] gets updated from parent component
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sliderOptions']) {
      if (this.sliderOptions) this.loadSlider()
    }
  }

  loadSlider() {
    this.value = this.sliderOptions.value
    this.options = {
      floor: this.sliderOptions.ticks[0],
      ceil: this.sliderOptions.ticks[this.sliderOptions.ticks.length - 1],
      readOnly: true,
      tickStep: this.sliderOptions.ticks.length,
      ticksArray: this.sliderOptions.ticks,
      showSelectionBar: true,
      showOuterSelectionBars: true,
      hideLimitLabels: true,
      getLegend: (value): string => {
        return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '%';
      },
      translate: (): string => {
        return ''
      },
    };
    setTimeout(() => { this.setSliderUI() })
  }

  setSliderUI() {
    const elements = document.getElementById(this.sliderOptions.id)?.getElementsByClassName("ngx-slider-span ngx-slider-pointer ngx-slider-pointer-min")
    if (elements?.length) {
      const currentStyle = elements[0].getAttribute("style")
      elements[0].setAttribute('style', `${currentStyle} background-color:${this.sliderOptions.color} !important`)
      if (this.sliderOptions?.label) {
        const ticksLegends = document.getElementById(this.sliderOptions.id).getElementsByClassName("ngx-slider-span ngx-slider-tick-legend")
        for (let i = 0; i < ticksLegends.length; i++) {
          ticksLegends[i].innerHTML = ''
          const tick = `${this.sliderOptions?.ticks[i]}${this.sliderOptions?.tickUnit}`
          ticksLegends[i].innerHTML = tick
          ticksLegends[i].setAttribute('style', 'font-weight:400 !important')
          if (i == this.sliderOptions?.tickIndexForLabel) {
            ticksLegends[i].innerHTML = `${tick}<br><span class='slider-label'>${this.sliderOptions?.label}</span>`
            ticksLegends[i].setAttribute('style', 'font-weight:700 !important')
          } else if (i == 0 && this.sliderOptions?.ceilLabel?.length) {
            ticksLegends[i].innerHTML = `${tick}<br><span class='ciel-floor-label'>${this.sliderOptions?.ceilLabel}</span>`
          } else if (i == ticksLegends.length - 1 && this.sliderOptions?.floorLabel?.length) {
            ticksLegends[i].innerHTML = `${tick}<br><span class='ciel-floor-label'}>${this.sliderOptions?.floorLabel}</span>`
          }
        }
      }
    }
  }

}
