import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Tab } from '../../../types/app.interface';

@Component({
  selector: 'app-tabs',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss'
})
export class TabsComponent {

  @Output() TabSelection = new EventEmitter<Tab>()

  tabs: Tab[] = [
    { name: 'Overview', path: "/" },
    { name: 'Location', path: '/score' },
    { name: 'Comparison', path: '/comparison' },
  ]
  selectedTabName = this.route.snapshot.data['name']

  constructor(private route: ActivatedRoute) { }

}


