import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DEFAULT_TIME_PERIOD, TIME_PERIODS } from '../../../utils/app.constant';
import { TimePeriod } from '../../../types/app.interface';

@Component({
  selector: 'app-time-period-toggle',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './time-period-toggle.component.html',
  styleUrl: './time-period-toggle.component.scss'
})
export class TimePeriodToggleComponent {

  @Output() TimePeriodSelection = new EventEmitter<TimePeriod>()

  @Input() selectedTimePeriod: TimePeriod = DEFAULT_TIME_PERIOD

  timePeriods: TimePeriod[] = TIME_PERIODS

  onTimePeriodToggle(toggleDirection: string) {
    const selectedItemIdx = this.timePeriods.findIndex(_ => _.key == this.selectedTimePeriod.key)
    switch (toggleDirection) {
      case "right":
        if (this.selectedTimePeriod.isLast) return;
        this.selectedTimePeriod = this.timePeriods[selectedItemIdx + 1]
        break;
      case "left":
        if (this.selectedTimePeriod.isFirst) return;
        this.selectedTimePeriod = this.timePeriods[selectedItemIdx - 1]
        break;
    }
    this.TimePeriodSelection.emit(this.selectedTimePeriod)
  }

}
