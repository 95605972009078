import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { INDICTORS_DICT, DEFAULT_INDICATOR_NAME, DEFAULT_TIME_PERIOD, RISK_KEY, RESILIENCE_KEY } from '../../../utils/app.constant';
import { TimePeriod } from '../../../types/app.interface';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { MapComponent } from '../../../shared/components/map/map.component';
import { TabsComponent } from '../../../shared/components/tabs/tabs.component';
import { TimeSelectionComponent } from '../../../shared/components/time-selection/time-selection.component';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'app-overview',
  standalone: true,
  imports: [
    CommonModule,
    TabsComponent,
    MapComponent,
    TimeSelectionComponent,
    LoaderComponent
  ],
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.scss'
})
export class OverviewComponent implements OnInit, AfterViewChecked {

  @ViewChild('canvas') canvasRef: ElementRef;
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  gkhead = new Image();

  indicatorsDict = INDICTORS_DICT
  selectedIndicatorName = DEFAULT_INDICATOR_NAME
  selectedTimePeriod: TimePeriod = DEFAULT_TIME_PERIOD
  selectedImage = INDICTORS_DICT[DEFAULT_INDICATOR_NAME]['mapImage'][DEFAULT_TIME_PERIOD.key];

  dataSets = INDICTORS_DICT[DEFAULT_INDICATOR_NAME].dataSet;

  minZoom = 2;
  maxZoom = 20;
  currentZoom = 6

  initialView = [350, 450]  // [x , y]
  initialZoom = 2.5

  datasetContainerHeight: string

  showLoader: boolean;

  mapImpactType: string = RISK_KEY

  datasetImpactType: string = RISK_KEY

  mapDecription = {
    [RISK_KEY]: "Physical Climate Risk quantifies the vulnerability of a specific location to particular types of natural hazards influenced by climate change. Elevated risk levels suggest that the likelihood of occurrence of a given hazard is increased, and that each event may be more severe in the future.",
    [RESILIENCE_KEY]: "Resilience-Adjusted Risk recalibrates Physical Climate Risk by incorporating local adaptation features. By implementing hazard-specific adaptation measures, a location can decrease its vulnerability, reduce its risk and enhance its overall performance."
  }

  showWelcomeBanner: boolean = true;

  constructor(private cdr: ChangeDetectorRef, private appService: AppService) {
    if (this.appService.getAppState()?.hasOwnProperty('showWelcomeBanner')) this.showWelcomeBanner = this.appService.getAppState().showWelcomeBanner
  }

  ngOnInit(): void {
    this.onLoad()
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges()
  }

  onDatasetImpactChange(type) {
    this.datasetImpactType = type
    this.dataSets = this.indicatorsDict[this.selectedIndicatorName].dataSet[this.datasetImpactType]
  }

  selectIndicator(item) {
    this.selectedIndicatorName = item.key;
    this.dataSets = item.value.dataSet[this.datasetImpactType];
    this.setMapImage()
  }

  onCheckBoxChange(event) {
    if (event.target.checked) {
      this.appService.updateAppLocalStorage('showWelcomeBanner', false)
    }
  }

  redraw() {
    // Redraw canvas
    var canvas = document.getElementsByTagName('canvas')[0];
    var ctx: any = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(this.gkhead, 0, 0);
  }

  loadImageAsync(url) {
    var self = this;
    return new Promise(function (resolve, reject) {
      // Set the src attribute to the image
      self.gkhead.src = url;
      // Set up event handlers for successful and failed loading
      self.gkhead.onload = function () {
        resolve(self.gkhead); // Resolve the promise with the loaded image
      };
      self.gkhead.onerror = function () {
        reject(new Error("Failed to load image")); // Reject the promise with an error
      };
    });
  }

  async setMapImage() {
    try {
      this.showLoader = true
      this.selectedImage = this.indicatorsDict[this.selectedIndicatorName]['mapImage'][this.mapImpactType][this.selectedTimePeriod.key]
      this.gkhead.src = this.selectedImage;
      await this.loadImageAsync(this.selectedImage)
      this.redraw()
      this.showLoader = false
    } catch (e: any) {
      console.error(e.message);
    }
  }

  onLoad() {

    this.setMapImage()

    var self = this;
    var canvas = document.getElementsByTagName('canvas')[0];
    canvas.width = 3200;
    canvas.height = 2400

    this.gkhead = new Image;

    var ctx: any = canvas.getContext('2d');
    trackTransforms(ctx);

    function redraw() {


      // Clear the entire canvas
      var p1 = ctx.transformedPoint(0, 0);
      var p2 = ctx.transformedPoint(canvas.width, canvas.height);
      ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);

      ctx.save();
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.restore();

      ctx.drawImage(self.gkhead, 0, 0);

    }
    redraw();

    var lastX = canvas.width / 2, lastY = canvas.height / 2;

    var dragStart, dragged;

    canvas.addEventListener('mousedown', function (evt) {
      document.body.style['mozUserSelect'] = document.body.style.webkitUserSelect = document.body.style.userSelect = 'none';
      lastX = evt.offsetX || (evt.pageX - canvas.offsetLeft);
      lastY = evt.offsetY || (evt.pageY - canvas.offsetTop);
      dragStart = ctx.transformedPoint(lastX, lastY);
      dragged = false;
    }, false);

    canvas.addEventListener('mousemove', function (evt) {

      lastX = evt.offsetX || (evt.pageX - canvas.offsetLeft);
      lastY = evt.offsetY || (evt.pageY - canvas.offsetTop);
      dragged = true;

      if (dragStart) {
        var pt = ctx.transformedPoint(lastX, lastY);
        ctx.translate(pt.x - dragStart.x, pt.y - dragStart.y);
        redraw();
      }
    }, false);

    canvas.addEventListener('mouseup', function (evt) {
      dragStart = null;
      if (!dragged) zoom(evt.shiftKey ? -1 : 1);
    }, false);

    var scaleFactor = 1.1;

    var zoom = function (clicks) {
      var pt = ctx.transformedPoint(lastX, lastY);
      ctx.translate(pt.x, pt.y);
      var factor = Math.pow(scaleFactor, clicks);
      ctx.scale(factor, factor);
      ctx.translate(-pt.x, -pt.y);
      // ctx.translate(pt.x - dragStart.x, pt.y - dragStart.y);
      redraw();
    }


    var handleScroll = function (evt) {
      let delta = evt.deltaY > 0 ? -1 : 1; // Adjust for direction
      const newZoom = self.currentZoom + delta;

      // Ensure newZoom stays within bounds
      if (newZoom >= self.minZoom && newZoom <= self.maxZoom) {
        self.currentZoom = newZoom;
        zoom(delta);
      }
      // Prevent default scroll behavior
      evt.preventDefault();
    };


    ctx.translate(self.initialView[0], self.initialView[1]);
    zoom(self.initialZoom)
    // 1416.7470703125 331.1249084472656 1419.3408203125 333.7186584472656

    canvas.addEventListener('DOMMouseScroll', handleScroll, true);
    canvas.addEventListener('mousewheel', handleScroll, true);
    // canvas.addEventListener('wheel', handleScroll, { passive: false });

    // };

    self.gkhead.src = this.selectedImage;

    // Adds ctx.getTransform() - returns an SVGMatrix
    // Adds ctx.transformedPoint(x,y) - returns an SVGPoint
    function trackTransforms(ctx) {
      var svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
      var xform = svg.createSVGMatrix();
      ctx.getTransform = function () { return xform; };

      var savedTransforms = [];
      var save = ctx.save;
      ctx.save = function () {
        savedTransforms.push(xform.translate(0, 0));
        return save.call(ctx);
      };

      var restore = ctx.restore;
      ctx.restore = function () {
        xform = savedTransforms.pop();
        return restore.call(ctx);
      };

      var scale = ctx.scale;
      ctx.scale = function (sx, sy) {
        xform = xform.scaleNonUniform(sx, sy);
        return scale.call(ctx, sx, sy);
      };

      var rotate = ctx.rotate;
      ctx.rotate = function (radians) {
        xform = xform.rotate(radians * 180 / Math.PI);
        return rotate.call(ctx, radians);
      };

      var translate = ctx.translate;
      ctx.translate = function (dx, dy) {
        xform = xform.translate(dx, dy);
        return translate.call(ctx, dx, dy);
      };

      var transform = ctx.transform;
      ctx.transform = function (a, b, c, d, e, f) {
        var m2 = svg.createSVGMatrix();
        m2.a = a; m2.b = b; m2.c = c; m2.d = d; m2.e = e; m2.f = f;
        xform = xform.multiply(m2);
        return transform.call(ctx, a, b, c, d, e, f);
      };

      var setTransform = ctx.setTransform;
      ctx.setTransform = function (a, b, c, d, e, f) {
        xform.a = a;
        xform.b = b;
        xform.c = c;
        xform.d = d;
        xform.e = e;
        xform.f = f;
        return setTransform.call(ctx, a, b, c, d, e, f);
      };

      var pt = svg.createSVGPoint();
      ctx.transformedPoint = function (x, y) {
        pt.x = x; pt.y = y;
        return pt.matrixTransform(xform.inverse());
      }
    }
  }

  returnZero() { return 0 }

  get datasetScrollHeight(): string | void {
    const datasetsListElement = document.getElementsByClassName('scroll-x')[0]
    const indicatorsElement = document.getElementsByClassName('indicators-container')[0]
    if (datasetsListElement && indicatorsElement) {
      const calculatedHeight = `${(indicatorsElement['offsetTop'] - datasetsListElement['offsetTop']) - 15}px`
      return calculatedHeight
    }
  }

}
