import { CommonModule } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { INDICTORS_DICT, DEFAULT_TIME_PERIOD } from '../../../utils/app.constant';
import { TimePeriod } from '../../../types/app.interface';
import { ComparisonTablePipe } from '../../../pipes/comparison-table.pipe';
import { AppService } from '../../../services/app.service';
import { MapComponent } from '../../../shared/components/map/map.component';
import { TabsComponent } from '../../../shared/components/tabs/tabs.component';
import { TimePeriodToggleComponent } from '../../../shared/components/time-period-toggle/time-period-toggle.component';
import { TimeSelectionComponent } from '../../../shared/components/time-selection/time-selection.component';

@Component({
  selector: 'app-comparison',
  standalone: true,
  imports: [
    CommonModule,
    MapComponent,
    TabsComponent,
    TimePeriodToggleComponent,
    ComparisonTablePipe,
    TimeSelectionComponent
  ],
  templateUrl: './comparison.component.html',
  styleUrl: './comparison.component.scss'
})
export class ComparisonComponent implements AfterViewChecked {

  indicatorsDict = INDICTORS_DICT

  selectedTimePeriod: TimePeriod = DEFAULT_TIME_PERIOD

  response: any

  overallRowColors = {
    "Very Low Risk": {
      "background": 'rgba(0, 153, 153, 0.3)'
    },
    "Very Low": {
      "background": 'rgba(0, 153, 153, 0.3)'
    },
    "Low Risk": {
      "background": 'rgba(0, 153, 153, 0.3)'
    },
    "Low": {
      "background": 'rgba(0, 153, 153, 0.3)'
    },
    "Medium Risk": {
      "background": 'rgba(234, 174, 20, 0.3)'
    },
    "Medium": {
      "background": 'rgba(234, 174, 20, 0.3)'
    },
    "High Risk": {
      "background": "rgba(129, 0, 0, 0.3)"
    },
    "High": {
      "background": "rgba(129, 0, 0, 0.3)"
    },
    "Very High Risk": {
      "background": "rgba(129, 0, 0, 0.3)"
    },
    "Very High": {
      "background": "rgba(129, 0, 0, 0.3)"
    }
  };

  constructor(public appService: AppService, private cdr: ChangeDetectorRef) {
    this.appService.getData().subscribe((response) => { this.response = response })
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges()
  }


  returnZero() { return 0 }


}
