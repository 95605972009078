<div class="footer">
  <div class="map-footer-container px-3 py-2">
    <div class="time-toggle">
      <div class="d-flex align-items-center">
        <img class="transform-180 cursor-pointer" src="../assets/images/svgs/polygon.svg"
          (click)="onTimePeriodToggle('left')" [ngClass]="{'disabled' : selectedTimePeriod.isFirst }">
        <div class="px-2">
          <span class="fw-700 fs-24 lh-normal">{{selectedTimePeriod.year}}</span> <br>
        </div>
        <img class="cursor-pointer" src="../assets/images/svgs/polygon.svg" (click)="onTimePeriodToggle('right')"
          [ngClass]="{'disabled' : selectedTimePeriod.isLast }">
      </div>
      <span class="fs-14 fw-400 lh-normal">{{selectedTimePeriod.label}}</span>
    </div>
    <div class="century-legend-container">
      <div class="time-periods-labels-container mr-2 px-3 legend-box" *ngIf="showCenturyLegend">
        <span *ngFor="let item of timePeriods" class="fs-11 fw-600 lh-normal time-period-label underline-with-circles"
          [ngClass]="{'underline-with-circles-white' : item.label === selectedTimePeriod.label, 'underline-with-circles-grey' : item.label !== selectedTimePeriod.label  }">
          <span class="px-2 cursor-pointer"
            (click)="selectedTimePeriod = item; TimePeriodSelection.emit(item)">{{item.label}}
          </span></span>
      </div>
      <div class="legend">
        <img class="legend-box fit-cover" src="{{mapLegendIcon}}">
      </div>
    </div>
  </div>
</div>
