<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 px-0">
      <div class="container-fluid br-bottom">
        <div class="row">
          <div class="col-md-8 px-0">
            <app-tabs></app-tabs>
          </div>
          <div class="col-md-4 px-0"></div>
        </div>
      </div>
      <div class="px-3 pb-2">
        <div class="time-period-label-container mt-4">
          <div class="d-flex align-items-center">
            <img src="../assets/images/svgs/comparison.svg">
            <span class="fs-20 fw-600 lh-150-prcnt capitalize pl-2">Location Comparison</span>
          </div>
          <div class="time-period">
            <app-time-period-toggle [selectedTimePeriod]="selectedTimePeriod"
              (TimePeriodSelection)="selectedTimePeriod = $event;"></app-time-period-toggle>
          </div>
        </div>
      </div>
      <div class="px-3 pb-3 table-container"
        [ngStyle]="{ 'height' : appService.getContainerHeight('table-container') }">
        <div class="d-flex mt-3">
          <div class="column py-3 px-3 w-50 fs-12 fw-600 lh-normal d-flex align-items-center">Risk Type</div>
          <div
            class="column py-3 px-2 w-25 fs-12 fw-600 lh-normal text-center d-flex align-items-center justify-content-center"
            *ngFor="let item of response">
            {{item.locationData.address}}
          </div>
        </div>
        <!-- Over all Risk-->
        <table class="table mb-2">
          <tr>
            <td rowspan="2" class="fs-12 fw-600 lh-normal py-3 px-3">Overall Risk</td>
            <td class="fs-12 fw-600 lh-normal py-3 px-3">Physical</td>
            <td class="py-3 fs-12 fw-700 lh-normal text-center"
              [ngStyle]="{ 'background-color' : overallRowColors[item.data.risk.Headline.Cat[selectedTimePeriod.key]].background }"
              *ngFor="let item of response">{{item.data.risk.Headline.Score[selectedTimePeriod.key]}}</td>
          </tr>
          <tr>
            <td class="fs-12 fw-600 lh-normal py-3 px-3">Resilience Adjusted</td>
            <td class="py-3 fs-12 fw-700 lh-normal text-center"
              [ngStyle]="{ 'background-color' : overallRowColors[item.data.resilience.Headline.Cat[selectedTimePeriod.key]].background }"
              *ngFor="let item of response">{{item.data.resilience.Headline.Score[selectedTimePeriod.key]}}</td>
          </tr>
        </table>

        <!-- Indicators -->
        <table class="table mb-0 indicators-table" *ngFor="let item of indicatorsDict | keyvalue : returnZero"
          [ngClass]="{ 'd-none' : item.key === 'Overall' }">
          <tr>
            <td rowspan="2" class="fs-12 fw-600 lh-normal py-3 px-3 bg-black">{{item.key}}</td>
            <td class="fs-12 fw-600 lh-normal py-3 px-3 bg-black">Physical</td>
            <td class="column py-3 fs-12 fw-400 lh-normal text-center"
              [ngStyle]="{ 'background-color' : object | comparisonTable : item.key : selectedTimePeriod : 'risk' : 'background-color' }"
              *ngFor="let object of response">
              {{ object | comparisonTable : item.key : selectedTimePeriod : 'risk' : 'value'  }}
            </td>
          </tr>
          <tr>
            <td class="fs-12 fw-600 lh-normal py-3 px-3 bg-black">Resilience Adjusted</td>
            <td class="column py-3 fs-12 fw-400 lh-normal text-center"
              [ngStyle]="{ 'background-color' : object | comparisonTable : item.key : selectedTimePeriod : 'resilience' : 'background-color' }"
              *ngFor="let object of response">
              {{ object | comparisonTable : item.key : selectedTimePeriod : 'resilience' : 'value'  }}
            </td>
          </tr>
        </table>

      </div>

    </div>
    <div class="col-md-6 border-left px-0 screen-height">
      <div class="h-100 relative">
        <app-map></app-map>
        <app-time-selection (TimePeriodSelection)="selectedTimePeriod = $event;" [showCenturyLegend]="false"
          [mapLegendIcon]="'assets/images/svgs/overview-map-legends/overall.svg'"
          [selectedTimePeriod]="selectedTimePeriod"></app-time-selection>
      </div>
    </div>
  </div>
</div>
