import { Component, Input } from '@angular/core';
import { Indicator } from '../../../types/app.interface';
import { RESILIENCE_FEATURE } from '../../../utils/app.constant';
import { CommonModule } from '@angular/common';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'app-resilience-features',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './resilience-features.component.html',
  styleUrl: './resilience-features.component.scss'
})
export class ResilienceFeaturesComponent {

  features = RESILIENCE_FEATURE
  @Input() selectedIndicator: Indicator;

  constructor(public appService: AppService) { }

}
