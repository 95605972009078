<div id="features-container" *ngIf="selectedIndicator">
  <!-- headings -->
  <div class="w-100 d-flex align-items-center border-none my-2">
    <div class="fs-11 fw-700 lh-normal" [ngStyle]="{'width' : (100 - (timePeriods.length * 20 ) + '%') }">Features
    </div>
    <div *ngFor="let item of timePeriods" class="relative"
      [ngStyle]="{'width' : (100 - (timePeriods.length * 20 ) + '%') }" (click)="TimePeriodSelection.emit(item)">
      <div class="overlap-grey-box" *ngIf="selectedTimePeriod?.key == item.key "
        [ngStyle]="{'height' : greyishBoxHeight}"></div>
      <div class="century-label">
        <div class="fs-11 fw-700 lh-normal relative zIndex-1">{{item.label}}</div>
        <div class="fs-11 fw-400 lh-normal relative zIndex-1">{{item.year}}</div>
      </div>
    </div>
  </div>
  <!-- features description and values -->
  <div>
    <div class="feature-detail-bar w-100 d-flex align-items-center" *ngFor="let item of selectedIndicator.features">
      <div class="px-2" [ngStyle]="{'width' : (100 - (timePeriods.length * 20 ) + '%') }">
        <div class="fs-11 fw-400 lh-normal">{{item.Description}} </div>
      </div>
      <div *ngFor="let century of timePeriods" class="cursor-pointer text-center relative zIndex-1"
        [ngStyle]="{'width' : (100 - (timePeriods.length * 20 ) + '%') }" (click)="TimePeriodSelection.emit(century)">
        <span class="{{century.key}} fs-14 fw-600 lh-24">
          {{item[century.key]}}
          <span class="fs-11 fw-400 lh-24 capitalize">{{item.Unit}}</span>
        </span>
      </div>
    </div>
  </div>
</div>
