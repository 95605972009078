<div id="features-container" *ngIf="selectedIndicator">
  <div class="w-100 d-flex align-items-center border-none my-2 header">
    <div class="fs-11 fw-700 lh-normal" [ngStyle]="{'width' : (100 - (features.length * 20 ) + '%') }">
      Features
    </div>
    <div *ngFor="let item of features" class="relative" [ngStyle]="{'width' : (100 - (features.length * 20 ) + '%') }">
      <div class="fs-11 fw-700 lh-normal relative zIndex-1 text-center">{{item.label}}</div>
    </div>
  </div>
  <ng-content></ng-content>
  <!-- class="features-list overflow-y-features"[ngStyle]="{ 'height' : appService.getContainerHeight('features-list') }" -->
  <div class="features-list">
    <div class="feature-detail-bar w-100 d-flex align-items-center" *ngFor="let item of selectedIndicator.features">
      <div [ngStyle]="{'width' : (100 - (features.length * 20 ) + '%') }">
        <div class="fs-11 fw-400 lh-normal px-2">{{item.Description}} </div>
      </div>
      <div *ngFor="let feature of features" class="text-center relative zIndex-1"
        [ngStyle]="{'width' : (100 - (features.length * 20 ) + '%') }">
        <span class="{{feature.key}} fs-14 fw-600 lh-24">
          {{item[feature.key]}}
          <span class="fs-11 fw-400 lh-24 capitalize">{{item.Unit}}</span>
        </span>
      </div>
    </div>
  </div>
</div>
