import { Routes } from '@angular/router';
import { ComparisonComponent } from './pages/comparison/comparison/comparison.component';
import { LocationScoreComponent } from './pages/location/location-score/location-score.component';
import { OverviewComponent } from './pages/overview/overview/overview.component';


export const routes: Routes = [
    {
        path: '',
        component: OverviewComponent,
        data: { name: "Overview" }
    },
    {
        path: 'score',
        component: LocationScoreComponent,
        data: { name: "Location" }
    },
    {
        path: 'comparison',
        component: ComparisonComponent,
        data: { name: "Comparison" }
    }
];
