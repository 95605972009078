<div>
  <div class="toggle-container d-flex justify-content-between align-items-center">
    <span class="image-container" (click)="onTimePeriodToggle('left')">
      <img class="transform-180 " [ngClass]="{'disabled' : selectedTimePeriod.isFirst }"
        src="../assets/images/svgs/polygon.svg">
    </span>
    <span class="fs-12 fw-400 lh-22">{{selectedTimePeriod.label}} ({{selectedTimePeriod.year}})</span>
    <span class="image-container" (click)="onTimePeriodToggle('right')">
      <img [ngClass]="{'disabled' : selectedTimePeriod.isLast }" src="../assets/images/svgs/polygon.svg">
    </span>
  </div>
</div>
