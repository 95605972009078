<div class="p-4">
  <div>
    <div class="d-flex align-items-center">
      <img src="../../../assets/images/svgs/heat-stress.svg">
      <h1 class="uppercase fs-20 fw-600 lh-150-prcnt title mx-2">
        {{selectedIndicator?.headline.Title}}</h1>
      <span class="d-flex align-items-center ml-3 cursor-pointer" (click)="closeDataTab()">
        <img src="../../../assets/images/svgs/polygon-arrow.svg">
        <span class="fs-12 fw-400 lh-normal mono-300 pl-2">Close Data Tab</span>
      </span>
    </div>
    <p class="fs-12 fw-400 lh-150-prcnt my-4" innerHtml="{{selectedIndicator?.headline.Explainer}}"></p>
  </div>
  <span class="dataset-label uppercase fs-14 fw-600 lh-150-prcnt my-2">DATASETS</span>
  <div class="d-flex mt-4">
    <div class="fs-12 fw-400 lh-24 py-2 mono-300 w-50 text-center" [ngClass]="{'selected' : impactType == 'risk' }">
      <span class="cursor-pointer" (click)="ChangeImpactType.emit('risk')">Risk Data</span>
    </div>
    <div class="fs-12 fw-400 lh-24 py-2 w-50 text-center" [ngClass]="{'selected' : impactType == 'resilience' }">
      <span class="cursor-pointer" (click)="ChangeImpactType.emit('resilience')">Resilience Data</span>
    </div>
  </div>
  <div class="mt-4 overflow-y" [ngStyle]="{ 'height' : appService.getContainerHeight('indicators-container') }">
    <ng-container [ngSwitch]="impactType" class="test">
      <app-risk-features *ngSwitchCase="'risk'" [selectedIndicator]="selectedIndicator"
        [selectedTimePeriod]="selectedTimePeriod" [greyishBoxHeight]="greyishBoxHeight"
        (TimePeriodSelection)="TimePeriodSelection.next($event)">
      </app-risk-features>
      <app-resilience-features *ngSwitchCase="'resilience'" [selectedIndicator]="selectedIndicator">
      </app-resilience-features>
    </ng-container>
  </div>
</div>
