<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 px-0 bg-black z-index-highest">
      <div class="br-bottom">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8 px-0">
              <app-tabs (TabSelection)="closeDataTab()"></app-tabs>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center px-3 mt-3">
        <div class="d-flex align-items-center w-60">
          <img src="../assets/images/svgs/marker.svg">
          <span
            class="fs-20 fw-600 lh-150-prcnt capitalize pl-2 pr-3">{{selectedLocationJson?.locationData.address}}</span>
        </div>
        <div class="w-40">
          <app-time-period-toggle [selectedTimePeriod]="selectedTimePeriod"
            (TimePeriodSelection)="onCenturyChange($event)">
          </app-time-period-toggle>
        </div>
      </div>
      <!-- slider box -->
      <div class="container-fluid px-3">
        <div class="row">
          <div class="col-md-6" *ngFor="let item of selectedLocationJson?.data | keyvalue : returnZero">
            <div class="slider-container mb-2 mt-3">
              <div class="d-flex justify-content-between align-items-center">
                <span class="fs-15 fw-700 lh-normal capitalize">
                  {{ item.key == 'risk' ? 'Physical Climate Risk' : 'Resilience-Adjusted Risk' }}
                </span>
                <span>
                  <span class="fs-36 fw-600 lh-normal"
                    [ngStyle]="{'color' : item.value['sliderOptions']?.color }">{{item.value['sliderOptions'].value}}</span>
                  <span class="fs-16 fw-400 lh-normal mono-300 pl-1">/100</span>
                </span>
              </div>
              <div>
                <app-marker-slider [sliderOptions]="item.value['sliderOptions']">
                </app-marker-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="selectedLocationJson" class="container-fluid px-3 overflow-y"
        [ngStyle]="{ 'height' : appService.getContainerHeight('indicators-container') }">
        <div class="row">
          <div class="col-md-6" *ngFor="let item of selectedLocationJson?.data | keyvalue : returnZero">
            <!-- indicators -->
            <div class="indicators-container">
              <div class="py-3">
                <div class="indicator-bar p-3 mb-3 cursor-pointer" *ngFor="let indicator of item.value['Indicators']"
                  [ngClass]="{'selected' : indicator.headline.Title === selectedIndicator?.headline.Title }"
                  (click)="onIndictorSelection(indicator); impactType = item.key">
                  <span class="d-flex align-items-center">
                    <img src="{{indicatorsDict[indicator.headline.Title]?.icon}}">
                    <span class="indicator-name fs-12 fw-600 lh-20 pl-2">{{indicator.headline.Title}}</span>
                  </span>
                  <span>
                    <span class="fs-24 fw-600 lh-normal"
                      [ngStyle]="{'color' : colors[indicator.headline.Cat[selectedTimePeriod.key]].color }">
                      {{indicator.headline.Score[selectedTimePeriod.key]}}
                    </span>
                    <span class="fs-12 fw-400 lh-normal mono-300 pl-1">/100</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 px-0 border-left">
      <!-- map and data tab -->
      <div class=" px-0 relative screen-height">
        <div class="data-tab h-100">
          <app-data-tab [selectedIndicator]="selectedIndicator" [selectedTimePeriod]="selectedTimePeriod"
            [impactType]="impactType" (TimePeriodSelection)="onCenturyChange($event)" (CloseDataTab)="closeDataTab()"
            (ChangeImpactType)="onPanelChange($event)">
          </app-data-tab>
        </div>
        <div class="h-100 relative">
          <app-map (LocationChange)="onLocationChange($event)"></app-map>
          <app-time-selection (TimePeriodSelection)="onCenturyChange($event)" [selectedTimePeriod]="selectedTimePeriod"
            [showCenturyLegend]="false" [mapLegendIcon]="'assets/images/svgs/overview-map-legends/overall.svg'">
          </app-time-selection>
        </div>
      </div>
    </div>
  </div>
</div>
